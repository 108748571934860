// Like Array.some() for the haystack, and like Array.every() for the needles.
// Recursively traverses an object to find matching any matching objects with
// all strings matched.
export function someValuesEveryString(haystack, needles) {
  if (!needles) {
    return haystack;
  }
  // we want a copy on purpose
  needles = needles
    .slice(0)
    .map(function (str) {
      return String(str || '')
        .trim()
        .toLowerCase();
    })
    .filter(str => {
      if (Boolean(str)) {
        return true;
      }
      // console.warn("ignoring bad search input '%s'", str);
      return false;
    });
  if (!needles.length) {
    return true;
  }
  return someStringsHelper(haystack, needles);
}

export function someStringsHelper(haystack, needles) {
  // Primitive types are
  //   - Boolean (always false)
  //   - Number (always false)
  //   - RegExp (don't handle)
  //   - Function (don't handle)
  //   - ES6+ special types Map, Symbol, etc (don't handle)
  //   - Array (traverse values)[]
  //   - Object as Object (traverse values)
  //   - Object as Map (traverse values, assume map key is a value)
  //   - String (partial match)
  if (!haystack) {
    return false;
  }
  if (true === haystack) {
    return false;
  }
  if (Array.isArray(haystack)) {
    return haystack.some(function (straw) {
      return someStringsHelper(straw, needles);
    });
  }
  if ('object' === typeof haystack) {
    return Object.values(haystack).some(function (straw) {
      return someStringsHelper(straw, needles);
    });
  }
  if ('string' !== typeof haystack) {
    // console.debug("ignoring %s '%s'", typeof haystack, haystack);
    return false;
  }

  // iterate backwards on a new array as values must be removed from back to
  // front for indexes to stay constant. Also reverse() modifies original
  needles
    .slice()
    .reverse()
    .forEach(function (needle, i, arr) {
      // "Haystack of Needles".toLowerCase().match("needle")
      //console.info(haystack, needle, haystack.toLowerCase().match(needle));
      if (haystack.toLowerCase().match(needle)) {
        // remove self from the original (in reverse order)
        needles.splice(arr.length - (i + 1), 1);
      }
    });

  // if all search terms have matched, we're done!
  if (!needles.length) {
    return true;
  }
  return false;
}

// if (require.main === module) {
//   require('./matcher-test.js')(someValuesEveryString);
//   module.export = function test(someValuesEveryString) {
//     [
//       {
//         inputs: [null, []],
//         expected: true,
//       },
//       {
//         inputs: [false, ['foo']],
//         expected: false,
//       },
//       {
//         inputs: ['foobar', ['foo']],
//         expected: true,
//       },
//       {
//         inputs: ['barfoo', ['foo']],
//         expected: true,
//       },
//       {
//         inputs: ['barfoobar', ['foo']],
//         expected: true,
//       },
//       {
//         inputs: ['bar', ['foo']],
//         expected: false,
//       },
//       {
//         inputs: ['foobar', ['foo', 'bar']],
//         expected: true,
//       },
//       {
//         inputs: ['foo', ['foo', 'bar']],
//         expected: false,
//       },
//       {
//         // TODO would we prefer to only match prefixes?
//         inputs: [
//           ['xfoox', 'xbarx'],
//           ['foo', 'bar'],
//         ],
//         expected: true,
//       },
//       {
//         // swap order of matches from order of search
//         inputs: [
//           ['xfoox', 'xbarx'],
//           ['bar', 'foo'],
//         ],
//         expected: true,
//       },
//       {
//         inputs: [
//           {
//             foo: 'baz',
//             bar: 'gralt',
//           },
//           ['foo', 'bar'],
//         ],
//         expected: false,
//       },
//       {
//         inputs: [
//           {
//             '1': 'foo',
//             '2': 'bar',
//           },
//           ['foo', 'bar'],
//         ],
//         expected: true,
//       },
//       {
//         // just trying something with really complex nesting
//         inputs: [
//           [
//             {
//               '1': [{ x: 'foo' }],
//               '2': { x: ['bar'] },
//             },
//           ],
//           ['foo', 'bar'],
//         ],
//         expected: true,
//       },
//       {
//         inputs: [
//           [
//             {
//               '1': [{ x: 'foo' }],
//               '2': { x: ['foo'] },
//               '3': { x: ['foo', 'foo foo', 'foo'] },
//             },
//           ],
//           ['foo', 'bar'],
//         ],
//         expected: false,
//       },
//       {
//         inputs: [
//           [
//             {
//               '1': [{ x: 'foo' }],
//               '2': { x: ['foo'] },
//               '3': { x: ['foo', 'foo foo', 'foo'] },
//             },
//           ],
//           ['bar', 'foo'],
//         ],
//         expected: false,
//       },
//       {
//         inputs: [
//           {
//             '1': 'baz',
//             '2': 'gralt',
//           },
//           [],
//         ],
//         expected: true,
//       },
//     ].forEach(function (conf, i) {
//       if (conf.expected !== someValuesEveryString.apply(null, conf.inputs)) {
//         console.error(i, conf);
//         throw new Error('Expected ' + conf.expected + ' result');
//       }
//     });
//   };
// }
