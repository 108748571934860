import { RSAA } from 'redux-api-middleware';
import { push } from 'connected-react-router';

import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import isObject from 'lodash-es/isObject';

import { requestHeaders } from '../../utils/api';
import { getAccountInfo } from '../Formation/operations';
import { getSettings } from '../Profile/operations';
import { setNotice } from '../UI/actions';
import {
  clearErrors,
  logoutUser,
  setInitLoading,
  setPartnerByCode,
  setPartnerCode,
  setUserFromToken,
  setUserReports,
} from './actions';
import * as types from './types';
import {
  decodeToken,
  // getStoredToken,
  removeEmailVerification,
  setEmailVerification,
} from './utils';
import { getCompanies, refreshCompanyToken } from '../Company/operations';
import { fetchUserRooms } from '../Data/operations';
import { getUserDocuments } from '../Document/operations';
// import { getCompanies, refreshCompanyToken, setCompany } from '../Company/operations';

const loadLiveMessenger = (email, name, first_name, last_name) => {
  const { REACT_APP_HS_CHAT_CODE } = process.env;
  var _hsq = (window._hsq = window._hsq || []);
  _hsq.push([
    'identify',
    {
      displayName: name,
      email,
      firstName: first_name || 'Not Given',
      lastName: last_name || 'Not Given',
    },
  ]);
  var body = document.querySelector('body');
  var script = document.createElement('script');

  script.setAttribute('type', 'text/javascript');
  script.setAttribute('id', 'hs-script-loader');
  script.setAttribute('async', true);
  script.setAttribute('defer', true);
  script.setAttribute('src', `//js-na1.hs-scripts.com/${REACT_APP_HS_CHAT_CODE}.js`);
  body.appendChild(script);
};

const fetchInitialUserData = async (dispatch, tokenData) => {
  // const { REACT_APP_LOG_ENV } = process.env;
  const { email, userId, name, first_name, last_name, impersonator_id } = tokenData;
  if (!userId) return;

  let fullName = name || email;

  // if (!email.includes('savvi.legal') && REACT_APP_LOG_ENV === 'app' && !impersonator_id) {
  //   window.FS.identify(userId, { email, name: fullName, company });
  // }
  if (email && !impersonator_id) {
    loadLiveMessenger(email, fullName, first_name, last_name);
  }
  return await dispatch(getSettings());
  // return await Promise.all([dispatch(getSettings()), dispatch(getFeatureChanges())]);
};

export const onLogout = path => {
  const {
    LOGOUT_REQUEST: REQUEST,
    LOGOUT_SUCCESS: SUCCESS,
    LOGOUT_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    await dispatch({
      [RSAA]: {
        endpoint: `/api/authn/session`,
        method: 'DELETE',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    dispatch(logoutUser());
    dispatch(push(path || '/login'));
    window.location.reload();
  };
};

export const verifyEmail = email => {
  const {
    VALIDATE_EMAIL_REQUEST: REQUEST,
    VALIDATE_EMAIL_SUCCESS: SUCCESS,
    VALIDATE_EMAIL_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/public/auth/email/${email}`,
        method: 'GET',
        headers: requestHeaders(),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(clearErrors());
      return actionResponse.payload;
    } else {
      if (actionResponse.payload.status !== 404 || !actionResponse.payload.silent) {
        dispatch(
          setNotice({
            type: 'error',
            message:
              actionResponse.payload.message ||
              'An error has occurred. If error persists, please contact our support.',
          }),
        );
      }
      throw await actionResponse.payload;
    }
  };
};

const loginUser = (creds, isCreating) => {
  const { LOGIN_REQUEST: REQUEST, LOGIN_SUCCESS: SUCCESS, LOGIN_FAILED: FAILED } = types;

  return async (dispatch, getState) => {
    const basicAuth = window.btoa(`${creds.email}:${creds.password}`);
    try {
      await dispatch(setInitLoading(true));
      const actionResponse = await dispatch({
        [RSAA]: {
          endpoint: '/api/authn/session/credentials',
          method: 'POST',
          headers: {
            ...requestHeaders(),
            Authorization: 'Basic ' + basicAuth,
          },
          types: [REQUEST, SUCCESS, FAILED],
        },
      });
      if (!actionResponse.error) {
        const decodedToken = decodeToken(actionResponse.payload.id_token);
        dispatch(setUserFromToken(decodedToken));
        if (!isCreating) {
          await fetchInitialUserData(dispatch, decodedToken);
          dispatch(setInitLoading(false));
        }
        dispatch(window.location.reload());
        return actionResponse.payload.id_token;
      } else {
        throw actionResponse.payload;
      }
    } catch (error) {
      dispatch(setInitLoading(false));
      throw error.message;
    }
  };
};

const handleValidateSession = async (
  dispatch,
  companyId,
  passCompaniesPayload,
  idToken,
) => {
  const decodedIdToken = decodeToken(idToken);
  const { email, name, first_name, last_name, userId, urlCompanyId } = decodedIdToken;

  dispatch(setUserFromToken(decodedIdToken));

  // Token containing Company Data.
  let decodedAccessToken = {};
  if (companyId || Number(urlCompanyId)) {
    let accessToken;
    await dispatch(refreshCompanyToken(companyId || urlCompanyId)).then(payload => {
      accessToken = payload.access_token;
    });
    if (accessToken) {
      decodedAccessToken = decodeToken(accessToken);
    }
  }
  const { company, impersonator_id, partners = [] } = decodedAccessToken;

  await fetchInitialUserData(dispatch, {
    email,
    userId,
    name,
    first_name,
    last_name,
    company,
    impersonator_id,
  });
  await dispatch(setInitLoading(false));
  if (passCompaniesPayload) {
    const companies = await dispatch(getCompanies());
    const isCompaniesEmpty = companies.length === 0;
    if (!isCompaniesEmpty) {
      dispatch(getUserDocuments());
      dispatch(fetchUserRooms());
      return await { isCompaniesEmpty, decodedIdToken };
    }
    const documents = await dispatch(getUserDocuments());
    const isSubscriptionAgreementSigned =
      documents.findIndex(
        doc => !!doc.box_file_id && doc.filename.includes('Subscription Agreement'),
      ) !== -1;
    if (!isSubscriptionAgreementSigned) {
      return await {
        isCompaniesEmpty,
        isLPPortalShowing: false,
        decodedIdToken,
      };
    }
    const rooms = await dispatch(fetchUserRooms());
    const isReportingRoomConnected =
      rooms.findIndex(room => room.type === 'report') !== -1;
    return await {
      isLPPortalShowing:
        isSubscriptionAgreementSigned &&
        isReportingRoomConnected &&
        partners.length === 0,
      isCompaniesEmpty,
      decodedIdToken,
    };
  }
  await dispatch(getCompanies());
  return await decodedIdToken;
};

const validateSession = (companyId, passCompaniesPayload, passedIdToken) => {
  const { TOKEN_REQUEST: REQUEST, TOKEN_SUCCESS: SUCCESS, TOKEN_FAILED: FAILED } = types;
  return async (dispatch, getState) => {
    try {
      const storedUserToken = JSON.parse(localStorage.id_tokens || '{}')['0'];

      if (passedIdToken || storedUserToken) {
        return await handleValidateSession(
          dispatch,
          companyId,
          passCompaniesPayload,
          passedIdToken || storedUserToken,
        );
      }
      const actionResponse = await dispatch({
        [RSAA]: {
          endpoint: '/api/authn/session/id_token', // alias to authn/refresh
          method: 'POST',
          headers: requestHeaders(),
          types: [REQUEST, SUCCESS, FAILED],
        },
      });
      if (!actionResponse) {
        // Bad login ju-ju. Logout and retry
        // localStorage.removeItem('id_tokens');
        // localStorage.removeItem('storedLocation');
        return await dispatch(setInitLoading(false));
      } else if (!actionResponse.error) {
        // if (passedIdToken) {
        //   localStorage.removeItem('id_token');
        // }
        // Token containing User Data
        return await handleValidateSession(
          dispatch,
          companyId,
          passCompaniesPayload,
          actionResponse.payload.id_token,
        );
      } else {
        throw actionResponse.payload;
      }
    } catch (error) {
      const url = window.location.href;
      const excludedRoutes = [
        'forgot-password',
        'login',
        'register',
        'reset-password',
        'plans',
        'terms',
        'privacy',
        'invites',
        'remote-login',
        'verify',
        'magic-link',
        'room',
        'outside-form',
      ];
      const matchingRouteIndex = excludedRoutes.findIndex(e => url.includes(e));
      if (matchingRouteIndex === -1) {
        dispatch(setNotice(get(error, 'message', 'An error has occurred.')));
        if (error.logout) {
          dispatch(onLogout());
        }
      }
      const logoutCodes = [
        'JSON_WEB_TOKEN_ERROR',
        'E_INVALID_SESSION',
        'E_SESSION_INVALID',
        'INVALID_JWT',
        'TOKEN_EXPIRED_ERROR',
      ];
      if (logoutCodes.includes(error.code)) {
        localStorage.removeItem('id_tokens');
        localStorage.removeItem('id_token');
        localStorage.removeItem('storedLocation');
        localStorage.removeItem('hide_verify');
      }
      dispatch(setInitLoading(false));
      throw error.message;
    }
  };
};

export const createUser = (
  userBody,
  companyBody,
  isCreatingWorkspace,
  isRoomRedirect,
) => {
  // use same as login
  // pass in create=true;
  const {
    CREATE_USER_REQUEST: REQUEST,
    CREATE_USER_FAILED: FAILED,
    CREATE_USER_SUCCESS: SUCCESS,
  } = types;
  const basicAuth = window.btoa(`${userBody.email}:${userBody.password}`);

  return async (dispatch, getState) => {
    await dispatch(setInitLoading(true));
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/authn/session/credentials',
        method: 'POST',
        headers: { ...requestHeaders(), Authorization: 'Basic ' + basicAuth },
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(userBody),
      },
    });
    if (!actionResponse.error) {
      dispatch(
        setNotice({
          message: 'Account Created! Please wait as we set everything up',
        }),
      );
      const decodedToken = decodeToken(actionResponse.payload.id_token);
      dispatch(setUserFromToken(decodedToken));
      dispatch(
        requestVerificationEmail({
          identifier: { type: 'email', value: userBody.email },
          template: 'verification-email',
        }),
      );
      if (isRoomRedirect) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        return await actionResponse.payload;
      }

      if (isCreatingWorkspace) {
        return await dispatch(createCompany(decodedToken, companyBody));
      } else {
        await fetchInitialUserData(dispatch, decodedToken);
        setTimeout(() => {
          dispatch(setInitLoading(true));
          window.location.reload();
        }, 1000);
        return await actionResponse.payload;
      }
    }

    if (isObject(actionResponse.payload)) {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload }));
    }
    throw actionResponse.payload;
  };
};

const createCompany = (decodedToken, body) => {
  const {
    CREATE_COMPANY_REQUEST: REQUEST,
    CREATE_COMPANY_FAILED: FAILED,
    CREATE_COMPANY_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/user/accounts',
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      // const url = window.location.href;
      // if (
      //   (url.includes('one-tap') || url.includes('onetap')) &&
      //   !url.includes('onetaptalent')
      // ) {
      //   localStorage.setItem('partner_code', 'onetaptalent');
      //   await fetchInitialUserData(dispatch, decodedToken);
      //   dispatch(setInitLoading(true));
      //   dispatch(setCompany(actionResponse.payload.account_id));
      //   setTimeout(() => {
      //     window.location.reload();
      //   }, 1200);
      //   return await actionResponse.payload;
      // }
      await fetchInitialUserData(dispatch, decodedToken);
      setTimeout(() => {
        dispatch(setInitLoading(true));
        window.location.reload();
      }, 1000);
      return await actionResponse.payload;
    }

    if (isObject(actionResponse.payload)) {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload }));
    }
    throw actionResponse.payload;
  };
};

const confirmUserEmail = (code, push) => {
  const {
    EMAIL_CONFIRM_REQUEST: REQUEST,
    EMAIL_CONFIRM_FAILED: FAILED,
    EMAIL_CONFIRM_SUCCESS: SUCCESS,
  } = types;
  const endpoint = `/api/user/confirm/${code}`;

  return async dispatch => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint,
        method: 'GET',
        headers: requestHeaders('onboard'),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });

    if (!actionResponse.error) {
      dispatch(
        setUserFromToken(
          decodeToken((actionResponse.payload || actionResponse).id_token),
        ),
      );
      push('/onboarding/basic-info');
    }
  };
};

const resendEmailConfirmation = () => {
  const {
    RESEND_EMAIL_CONFIRM_REQUEST: REQUEST,
    RESEND_EMAIL_CONFIRM_FAILED: FAILED,
    RESEND_EMAIL_CONFIRM_SUCCESS: SUCCESS,
  } = types;
  const endpoint = '/api/user/confirm/code/resend';

  return async dispatch => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint,
        method: 'POST',
        headers: requestHeaders('onboard'),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });

    if (!actionResponse.error) {
      dispatch(setNotice('New confirmation code sent!'));
    }
  };
};

const sendPasswordReset = email => {
  return async (dispatch, getState) => {
    await Promise.resolve(
      dispatch({
        [RSAA]: {
          endpoint: '/api/public/user/password/forgot',
          method: 'PATCH',
          headers: requestHeaders(),
          types: [
            types.SEND_PW_RESET_REQUEST,
            {
              type: types.SEND_PW_RESET_SUCCESS,
              payload: () =>
                dispatch(
                  setNotice(
                    "If there's a Savvi account linked to this email address, you will receive reset instructions in your inbox.",
                  ),
                ),
            },
            types.SEND_PW_RESET_FAILED,
          ],
          body: JSON.stringify({ email }),
        },
      }),
    );

    const { Auth } = getState();
    if (!Auth.isFetching) {
      if (isEmpty(Auth.errors)) {
      }
    }
  };
};

export const fetchValidatePassword = password => {
  return async (dispatch, getState) => {
    const basicAuth = window.btoa(`check:${password}`);
    const actionResponse = await Promise.resolve(
      dispatch({
        [RSAA]: {
          endpoint: '/api/public/auth/check-password',
          method: 'GET',
          headers: {
            ...requestHeaders(),
            Authorization: `Basic ${basicAuth}`,
          },
          types: [
            types.VALIDATE_PASSWORD_REQUEST,
            types.VALIDATE_PASSWORD_SUCCESS,
            types.VALIDATE_PASSWORD_FAILED,
          ],
        },
      }),
    );
    if (!actionResponse.error) {
      return await actionResponse.payload;
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
      throw await actionResponse.payload.message;
    }
  };
};

const resetPassword = ({ code, password }, push) => {
  return async (dispatch, getState) => {
    await Promise.resolve(
      dispatch({
        [RSAA]: {
          endpoint: '/api/public/user/password/reset/',
          method: 'POST',
          headers: requestHeaders(),
          types: [
            types.RESET_PASSWORD_REQUEST,
            {
              type: types.RESET_PASSWORD_SUCCESS,
              payload: () =>
                dispatch(
                  setNotice(
                    'Your password has been reset, you may now login, using your new password.',
                  ),
                ),
            },
            types.RESET_PASSWORD_FAILED,
          ],
          body: JSON.stringify({ code, password }),
        },
      }),
    );

    const { Auth } = getState();
    if (!Auth.isFetching) {
      if (isEmpty(Auth.errors)) {
        push('/login');
      }
    }
  };
};

const setHubspot = body => {
  const {
    SET_HUBSPOT_REQUEST: REQUEST,
    SET_HUBSPOT_FAILED: FAILED,
    SET_HUBSPOT_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    await dispatch({
      [RSAA]: {
        endpoint: '/api/account/hubspot',
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
  };
};

const clearNotification = (socket, id) => {
  socket.emit('read', [id * 1]);
  // socket.emit('get_all');
};

const addNewUser = body => {
  const {
    CREATE_NEW_USER_REQUEST: REQUEST,
    CREATE_NEW_USER_FAILED: FAILED,
    CREATE_NEW_USER_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/admin/add_user',
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      dispatch(
        setNotice(
          `${body.first_name} ${body.last_name} added as admin. Email invite sent.`,
        ),
      );
      dispatch(getAccountInfo());
      return await actionResponse.payload;
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: get(actionResponse, 'payload.message', 'An error as occurred.'),
        }),
      );
    }
  };
};

const changeOwner = (newOwnerId, fullName) => {
  const {
    CHANGE_OWNER_REQUEST: REQUEST,
    CHANGE_OWNER_FAILED: FAILED,
    CHANGE_OWNER_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/change_owner/${newOwnerId}`,
        method: 'PATCH',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice(`${fullName} Set as new Owner.`));
      dispatch(getAccountInfo());
      dispatch(getSettings());
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: get(actionResponse, 'payload.message', 'An error as occurred.'),
        }),
      );
    }
  };
};

const deleteUser = (userId, fullName) => {
  const {
    DELETE_USER_REQUEST: REQUEST,
    DELETE_USER_FAILED: FAILED,
    DELETE_USER_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/remove_user/${userId}`,
        method: 'DELETE',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice(`${fullName} has been removed.`));
      dispatch(getAccountInfo());
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: get(actionResponse, 'payload.message', 'An error as occurred.'),
        }),
      );
    }
  };
};

const getPartnerByCode = code => {
  const {
    GET_PARTNER_BY_CODE_REQUEST: REQUEST,
    GET_PARTNER_BY_CODE_FAILED: FAILED,
    GET_PARTNER_BY_CODE_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/public/partner/code/${code}`,
        method: 'get',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(setPartnerByCode(actionResponse.payload, code));
      await dispatch(setPartnerCode(code));
      return await actionResponse.payload;
    } else {
      console.error('get Partner by code error', actionResponse);
      await dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.payload.message || 'An error has occurred',
        }),
      );
      throw await actionResponse.payload;
    }
  };
};

const connectPartner = code => {
  const {
    CONNECT_PARTNER_REQUEST: REQUEST,
    CONNECT_PARTNER_FAILED: FAILED,
    CONNECT_PARTNER_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/partner/${code}`,
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(setNotice('Partner Connected.'));
      dispatch(setPartnerCode(''));
      dispatch(getAccountInfo());
      return await actionResponse.payload;
    } else {
      await dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.payload.message || 'An error has occurred',
        }),
      );
      throw await actionResponse;
    }
  };
};

export const removePartner = id => {
  const {
    REMOVE_PARTNER_REQUEST: REQUEST,
    REMOVE_PARTNER_FAILED: FAILED,
    REMOVE_PARTNER_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/partner/${id}`,
        method: 'DELETE',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(
        setNotice({ type: 'warning', message: actionResponse.payload.message }),
      );
      return await actionResponse.payload;
    } else {
      await dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.payload.message || 'An error has occurred',
        }),
      );
      throw await actionResponse;
    }
  };
};

export const requestVerificationEmail = body => {
  const {
    REQUEST_VERIFICATION_EMAIL_REQUEST: REQUEST,
    REQUEST_VERIFICATION_EMAIL_FAILED: FAILED,
    REQUEST_VERIFICATION_EMAIL_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    /**
     * body: {
     *  type,
     *  value,
     *  template: oneOf: ['email-change-code', 'reset-password', 'verification-email', 'magic-link']
     *  _developmentSendChallengeSecret: true,
     *  _skipEmail: true
     * }
     */
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/authn/challenge', //alias to /authn/challenge/order
        method: 'POST',
        headers: requestHeaders(),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    /**
     response: {
      "success": true,
      "id": "TJNOdm",
      "receipt": "7oHMZbcOwvCy7rmXslOKnB",
      "_expiry": "24h"
    }
     */
    if (!actionResponse.error) {
      return await setEmailVerification(body.template, actionResponse.payload)[
        body.template
      ];
    } else {
      await dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.payload.message || 'An error has occurred',
        }),
      );
      throw await actionResponse;
    }
  };
};

export const checkVerificationStatus = (id, receipt, templateKey) => {
  const {
    CHECK_VERIFICATION_STATUS_REQUEST: REQUEST,
    CHECK_VERIFICATION_STATUS_FAILED: FAILED,
    CHECK_VERIFICATION_STATUS_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/authn/challenge/${id}?receipt=${receipt}`,
        method: 'get',
        headers: requestHeaders(),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    /**
     * status pending response
     * {
          "success": true,
          "id": "2NmDib",
          "status": "pending",
          "ordered_at": "2021-08-10T20:01:21.498Z",
          "ordered_by": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36",
          "receipt": "zuQWS0PjUrmzajdvCrJn_R"
      }
     */
    if (!actionResponse.error) {
      return await actionResponse.payload;
    } else {
      // TODO: when and when not to remove local stored verification
      // if (actionResponse.payload.status !== 401) {
      //   removeEmailVerification(templateKey);
      // }
      throw await actionResponse.payload.message;
    }
  };
};

export const checkVerificationStatusByQuery = (id, queryStr, templateKey) => {
  const {
    CHECK_VERIFICATION_STATUS_REQUEST: REQUEST,
    CHECK_VERIFICATION_STATUS_FAILED: FAILED,
    CHECK_VERIFICATION_STATUS_SUCCESS: SUCCESS,
  } = types;

  /**
    :id=abc123
    ?receipt=yyyyyyyy
    &code=AB34-EF78
   */

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/authn/challenge/${id}${queryStr}`,
        method: 'get',
        headers: requestHeaders(),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    /**
     * status pending response
     * {
          "success": true,
          "id": "2NmDib",
          "status": "pending",
          "ordered_at": "2021-08-10T20:01:21.498Z",
          "ordered_by": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36",
          "receipt": "zuQWS0PjUrmzajdvCrJn_R"
      }
     */
    if (!actionResponse.error) {
      return await actionResponse.payload;
    } else {
      if (actionResponse.payload.status !== 401) {
        removeEmailVerification(templateKey);
      }
      throw await actionResponse.payload;
    }
  };
};

export const checkVerificationStatusByAccessCode = accessCode => {
  const {
    CHECK_VERIFICATION_STATUS_REQUEST: REQUEST,
    CHECK_VERIFICATION_STATUS_FAILED: FAILED,
    CHECK_VERIFICATION_STATUS_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/public/access_code/inspect?access_code=${accessCode}`,
        method: 'get',
        headers: requestHeaders(),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    /**
     * status pending response
     * {
        effective_id: 14,
        access_claims: { challenge_code: '7d2b3cd1', challenge_id: 'xOXjbshFUeEmvhUn' },
        room_id: 2458084614,
        created_at: '2022-06-29T23:58:46.000Z',
        updated_at: '2022-06-29T23:58:46.000Z',
        inviter: {
          email: 'services@savvi.legal',
          given_name: 'Dan',
          family_name: 'Andelin'
        }
      }
     */
    if (!actionResponse.error) {
      return await actionResponse.payload;
    } else {
      throw await actionResponse.payload;
    }
  };
};

// Delete magic link
export const deleteChallenge = (id, templateKey) => {
  const {
    DELETE_CHALLENGE_REQUEST: REQUEST,
    DELETE_CHALLENGE_FAILED: FAILED,
    DELETE_CHALLENGE_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/authn/challenge/${id}`,
        method: 'delete',
        headers: requestHeaders(),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    /**
     {
        "success": true,
        "status": "pending",
        "ordered_at": "2021-06-20T13:30:59Z",
        "ordered_by": "Chrome/x.y.z Windows 10",
        "verified_at": "",
        "verified_by": ""
      }
     */
    if (!actionResponse.error) {
      removeEmailVerification(templateKey);
      return await actionResponse.payload;
    } else {
      await dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.payload.message || 'An error has occurred',
        }),
      );
      throw await actionResponse.payload;
    }
  };
};

// export const finalizeEmailVerification = (body, templateKey, isLink) => {
//   const {
//     FINALIZE_EMAIL_VERIFICATION_REQUEST: REQUEST,
//     FINALIZE_EMAIL_VERIFICATION_FAILED: FAILED,
//     FINALIZE_EMAIL_VERIFICATION_SUCCESS: SUCCESS,
//   } = types;
//   /**
//    {
//     "id": "abc123",
//     "code": "AB34-EF78"
//     }
//    */

//   return async (dispatch, getState) => {
//     const actionResponse = await dispatch({
//       [RSAA]: {
//         endpoint: `/api/authn/challenge/finalize`,
//         method: 'post',
//         headers: requestHeaders(),
//         types: [REQUEST, SUCCESS, FAILED],
//         body: JSON.stringify(body),
//       },
//     });
//     /**
//      {
//         "id_token": "xxxx.yyyy.zzzz",
//         "access_token": "xxx2.yyy2.zzz2"
//       }
//      */
//     if (!actionResponse.error) {
//       await dispatch(setNotice('Verification Finalized.'));
//       const decodedToken = decodeToken(actionResponse.payload.id_token);
//       dispatch(setUserFromToken(decodedToken));
//       if (!isLink) {
//         removeEmailVerification(templateKey);
//       }
//       return await decodedToken;
//     } else {
//       if (actionResponse.payload.code !== 'E_CODE_RETRY' && !isLink) {
//         removeEmailVerification(templateKey);
//       }
//       if (actionResponse.payload.code === 'E_CODE_REDEEMED') {
//         dispatch(
//           setNotice(
//             actionResponse.payload.message || 'This code has already been redeemed.',
//           ),
//         );
//       } else {
//         dispatch(
//           setNotice({
//             type: 'error',
//             message: actionResponse.payload.message || 'An error has occurred',
//           }),
//         );
//       }
//       throw await actionResponse.payload;
//     }
//   };
// };

// finalize Email Verification,
export const exchangeChallenge = (id, body, templateKey, isLink) => {
  const {
    EXCHANGE_CHALLENGE_REQUEST: REQUEST,
    EXCHANGE_CHALLENGE_FAILED: FAILED,
    EXCHANGE_CHALLENGE_SUCCESS: SUCCESS,
  } = types;
  /**
   {
    "id": "abc123",
    "receipt": "yyyyyyyy"
    }
   */

  return async (dispatch, getState) => {
    let actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/authn/session/challenge/${id}`,
        method: 'post',
        headers: requestHeaders(),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    /**
     {
        "success": true,
        "status": "valid",
        "id_token": "xxxx.yyyy.zzzz"
        "access_token": "xxx2.yyy2.zzz2"
      }
     */
    if (actionResponse.payload.code === 'E_CODE_REDEEMED' && templateKey === 'room') {
      actionResponse.payload.message =
        'Link has been used before, redirected to room with limited access. Sign in for full access.';
    }
    if (!actionResponse.error) {
      await dispatch(setNotice('Verification Finalized.'));
      const decodedToken = decodeToken(actionResponse.payload.id_token);

      dispatch(setUserFromToken(decodedToken));
      if (actionResponse.payload.code !== 'E_CODE_RETRY' && !isLink && templateKey) {
        removeEmailVerification(templateKey);
      }
      if (actionResponse.payload.code === 'E_CODE_REDEEMED') {
        dispatch(
          setNotice({
            delay: 12000,
            message:
              actionResponse.payload.message || 'This code has already been redeemed.',
          }),
        );
      }
      return await actionResponse.payload;
    } else if (actionResponse.payload.code === 'E_CODE_REDEEMED') {
      dispatch(
        setNotice({
          delay: 13000,
          message:
            actionResponse.payload.message || 'This code has already been redeemed.',
        }),
      );
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.payload.message || 'An error has occurred',
        }),
      );
    }
    throw await actionResponse.payload;
  };
};

export const fetchUserReports = () => {
  const {
    GET_USER_REPORTS_REQUEST: REQUEST,
    GET_USER_REPORTS_SUCCESS: SUCCESS,
    GET_USER_REPORTS_FAILED: FAILED,
  } = types;
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/user/report`,
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(setUserReports(actionResponse.payload));
    } else {
      let err = new Error(actionResponse.payload?.message || 'An error has occurred.');
      throw err;
    }
  };
};

export {
  clearNotification,
  confirmUserEmail,
  connectPartner,
  getPartnerByCode,
  loginUser,
  resendEmailConfirmation,
  resetPassword,
  setHubspot,
  sendPasswordReset,
  validateSession,
  addNewUser,
  changeOwner,
  deleteUser,
};
