import { dom, library } from '@fortawesome/fontawesome-svg-core';
// Brands
import {
  faCcAmex,
  faCcDiscover,
  faGoogle,
  faCcMastercard,
  faCcVisa,
} from '@fortawesome/free-brands-svg-icons';
// Light *ex. icon={['fal', 'bell-slash']}
import {
  faArrowDown19,
  faArrowDownAZ,
  faArrowDownWideShort,
  faArrowUp19,
  faArrowUpAZ,
  faArrowUpWideShort,
  faArrowFromLeft,
  faArrowRotateLeft,
  faArrowUpFromSquare,
  faArrowToLeft,
  faBallotCheck,
  faBars as faBarsLight,
  faBell,
  faBellSlash,
  faBuilding,
  faBuildingCircleArrowRight,
  faBooks,
  faCalendarAlt,
  faCaretDown as faCaretDownLight,
  faCaretUp as faCaretUpLight,
  faChartPie,
  faCheck as faCheckLight,
  faCheckCircle as faCheckCircleLight,
  faCheckSquare,
  faChevronDown as faChevronDownLight,
  faChevronLeft as faChevronLeftLight,
  faChevronRight as faChevronRightLight,
  faCircle as faCircleLight,
  faClipboard,
  faClock,
  faClockRotateLeft,
  faCog,
  faCopy,
  faCreditCard,
  faDollarSign as faDollarSignLight,
  faEdit,
  faEllipsisV as faEllipsisVLight,
  faEnvelopeOpenText,
  faEraser,
  faExternalLink,
  faFeatherAlt,
  faFileAlt,
  faFileArrowDown,
  faFileCirclePlus,
  faFileContract,
  faFileDownload,
  faFileInvoiceDollar,
  faFile as faFileLight,
  faFileExport,
  faFilePlus,
  faFileSignature,
  faFilePen,
  faFileUpload,
  faFileUser,
  faHandshake,
  faHistory,
  faHome,
  faHouseUser,
  faHourglass,
  faInfoCircle,
  faLayerGroup,
  faLayerMinus,
  faLayerPlus,
  faLightbulb,
  faLink,
  faLinkSimple,
  faLinkSimpleSlash,
  faLockKeyhole,
  faMinus as faMinusLight,
  faMinusSquare,
  faMoneyCheck,
  faMoneyCheckEdit,
  faPaperPlane,
  faPenAlt,
  faPenField,
  faPenSlash,
  faPenToSquare,
  faPlus as faPlusLight,
  faPlusCircle,
  faPlusSquare,
  faPortrait,
  faQuestionCircle,
  faQuestion as faQuestionLight,
  faRectangleHistoryCirclePlus,
  faRocket,
  faScroll,
  faSearch,
  faShareFromSquare,
  faShoppingBasket,
  faSignature,
  faSort,
  faSortAmountDown,
  faSortAmountUp,
  faTasks,
  faTimes as faTimesLight,
  faTrashAlt,
  faUndo,
  faUserAlt,
  faUserCircle,
  faUserEdit,
  faUserMinus,
  faUserPlus as faUserPlusLight,
  faUserTie,
  faUsers,
  faUsersSlash,
  faUsersClass,
  faArrowRightToBracket,
} from '@fortawesome/pro-light-svg-icons';
// Regular *ex. icon={['far', 'caret-square-down']}
import {
  faCaretSquareDown,
  faCaretSquareUp,
  faCircle as faCircleReg,
  faDollarSign as faDollarSignReg,
  faArrowDownToLine,
  faFile as faFileReg,
  faFileImport,
  faFileSearch as faFileSearchReg,
  faLowVision,
  faPercent,
  faPrintSearch,
  faUnlink,
} from '@fortawesome/pro-regular-svg-icons';
// SOLID(default) *ex. icon="caret-down"**
import {
  faArchive,
  faArrowDown,
  faArrowUp,
  faArrowLeft,
  faArrowRight,
  faBars,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleInfo,
  faComments,
  faDownload,
  faEllipsisV,
  faEnvelope,
  faExclamation,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFile,
  faFileSearch,
  faInfo,
  faLock,
  faLongArrowLeft,
  faLongArrowRight,
  faMagnifyingGlass,
  faMinus,
  faPencil,
  faPencilAlt,
  faPhone,
  faPlus,
  faQuestion,
  faSlash,
  faSpinner,
  faSuitcase,
  faTimes,
  faTimesCircle,
  faTrademark,
  faTrash,
  faUser,
  faUserPlus,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  // Brands
  faCcAmex,
  faCcDiscover,
  faGoogle,
  faCcMastercard,
  faCcVisa,
  // Solid
  faArchive,
  faArrowDown,
  faArrowUp,
  faArrowLeft,
  faArrowRight,
  faBars,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleInfo,
  faComments,
  faDownload,
  faEllipsisV,
  faEnvelope,
  faExclamation,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFile,
  faFileSearch,
  faInfo,
  faLock,
  faLongArrowLeft,
  faLongArrowRight,
  faMagnifyingGlass,
  faMinus,
  faPencil,
  faPencilAlt,
  faPhone,
  faPlus,
  faQuestion,
  faSlash,
  faSpinner,
  faSuitcase,
  faTimes,
  faTimesCircle,
  faTrademark,
  faTrash,
  faUser,
  faUserPlus,

  // Regular
  faCaretSquareDown,
  faCaretSquareUp,
  faCircleReg,
  faDollarSignReg,
  faArrowDownToLine,
  faArrowRightToBracket,
  faFileReg,
  faFileImport,
  faFileSearchReg,
  faLowVision,
  faPercent,
  faPrintSearch,
  faUnlink,

  // Light
  faArrowDown19,
  faArrowDownAZ,
  faArrowDownWideShort,
  faArrowUp19,
  faArrowUpAZ,
  faArrowUpWideShort,
  faArrowFromLeft,
  faArrowRotateLeft,
  faArrowUpFromSquare,
  faArrowToLeft,
  faBallotCheck,
  faBarsLight,
  faBell,
  faBellSlash,
  faBooks,
  faBuilding,
  faBuildingCircleArrowRight,
  faCalendarAlt,
  faCaretDownLight,
  faCaretUpLight,
  faChartPie,
  faCheckLight,
  faCheckCircleLight,
  faCheckSquare,
  faChevronDownLight,
  faChevronLeftLight,
  faChevronRightLight,
  faCircleLight,
  faClipboard,
  faClock,
  faClockRotateLeft,
  faCog,
  faCopy,
  faCreditCard,
  faDollarSignLight,
  faEdit,
  faEllipsisVLight,
  faEnvelopeOpenText,
  faEraser,
  faExternalLink,
  faFeatherAlt,
  faFileAlt,
  faFileArrowDown,
  faFileCirclePlus,
  faFileContract,
  faFileDownload,
  faFileInvoiceDollar,
  faFileExport,
  faFileLight,
  faFilePlus,
  faFileSignature,
  faFilePen,
  faFileUpload,
  faFileUser,
  faHandshake,
  faHome,
  faHouseUser,
  faHistory,
  faHourglass,
  faInfoCircle,
  faLayerGroup,
  faLayerMinus,
  faLayerPlus,
  faLightbulb,
  faLink,
  faLinkSimple,
  faLinkSimpleSlash,
  faLockKeyhole,
  faMinusLight,
  faMinusSquare,
  faMoneyCheck,
  faMoneyCheckEdit,
  faPaperPlane,
  faPenAlt,
  faPenField,
  faPenSlash,
  faPenToSquare,
  faPlusLight,
  faPlusCircle,
  faPlusSquare,
  faPortrait,
  faQuestionCircle,
  faQuestionLight,
  faRectangleHistoryCirclePlus,
  faRocket,
  faSearch,
  faShareFromSquare,
  faSort,
  faSortAmountDown,
  faSortAmountUp,
  faScroll,
  faShoppingBasket,
  faSignature,
  faTasks,
  faTimesLight,
  faTrashAlt,
  faUndo,
  faUserAlt,
  faUserCircle,
  faUserEdit,
  faUserMinus,
  faUserPlusLight,
  faUsers,
  faUsersSlash,
  faUsersClass,
  faUserTie,
);

dom.watch();
