import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Field, FieldArray, Form } from 'formik';

import { CompanyName, ResourceSelect, Select } from '../../components/formik';
import WorkbenchActions from '../../components/WorkbenchActions';
import { options } from '../../data/StateData';
import FeatureTypes, {
  validateCompany,
  validateEmail,
  validateSelect,
} from '../../utils/FeatureTypes';

import { get, isArray, isEmpty, reduce, upperFirst } from 'lodash-es';
import { Button } from '../../components/common';
import InputStyledCheckbox from '../../components/common/InputStyledCheckbox';
import { useDispatch } from 'react-redux';
import { createOutsideForm } from '../../redux/modules/Transaction/operations';
import { updateProject } from '../../redux/modules/Project/operations';
import {
  InputCheckboxGroup,
  InputSelect,
  InputStyledTextField,
  InputTextarea,
} from '../../components/inputs';
import AlertModal from '../../components/AlertModal';
import { suffixOptions } from '../../components/formik/CompanyName/CompanyName';
import { mergeResources } from '../../redux/modules/Formation/operations';

const WorkbenchForm = ({
  customDocuments,
  formValidationErrors,
  handleCounselBlur,
  handleDelete,
  handleFormBlur,
  handleFormValidation,
  handleResourceName,
  initVals,
  isAssessment,
  isFetching,
  isValid,
  isModal,
  isReopeningTimeout,
  isSharingForm,
  isSafariMobile,
  isSubmitting,
  isInitQuestionnaire,
  isSubmitAttempted,
  values,
  params,
  project,
  questions,
  resetForm,
  resources,
  setIsSharingForm,
  setNotice,
  setResourceIds,
  setSubmitAttempted,
  submitCount,
  sharedFormDescription,
  transactionLabel,
  workflowCounselQuestions,
}) => {
  const dispatch = useDispatch();

  const handleSetResourceIds = (name, resourceIds) => setResourceIds(name, resourceIds);
  const shareFeatures = useMemo(
    () =>
      questions.reduce((dict, e) => {
        if (e.resourceArr.length > 0) {
          e.resourceArr.forEach(({ innerQuestions, resource_id }) => {
            innerQuestions.forEach(
              ({
                feature_type: { feature_type } = {},
                feature_type_id,
                id,
                required_question_id,
                resource_variable_name,
              }) => {
                dict.push({
                  feature_type_id,
                  id,
                  resource_id,
                  resource_variable_name,
                  required_question_id,
                  isSelected: true,
                  isDisabled: !!required_question_id,
                });
              },
            );
          });
        }
        return dict;
      }, []),
    [questions],
  );

  const [projectLabelInput, setProjectLabelInput] = useState(project.label);
  const [isSharingFormModalOpen, setIsSharingFormModalOpen] = useState(false);
  const [shareFormMeta, setShareFormMeta] = useState(() => ({
    name: transactionLabel,
    description: sharedFormDescription,
    email: '',
    send_email: true,
    finalize_on_submit: false,
  }));
  const [selectedFeatures, setSelectedFeatures] = useState(shareFeatures);
  const [shareFormEmailError, setShareFormEmailError] = useState('');

  const isProjectNameInputShowing = !!project?.template?.is_repeatable && isAssessment;
  const isPortalView = (project.template?.actions || []).length > 0;
  const projectType = isPortalView ? 'Portal Workflow' : 'Workflow';
  const projectNameQuestionLabel = `${projectType} Name`;
  const projectNameQuestionSublabel = `If you plan on doing multiple ${projectType}s of this same type then a unique ${projectType} name can help differentiate between them.`;
  const shareFormFinalizeBody = `Anyone with this shared form link will be able to review/update the inputs you selected. ${
    shareFormMeta.send_email
      ? `This will automatically send an email to ${shareFormMeta.email},`
      : 'This will not send an email, but'
  } you will also be able to share the form link to whoever you want. Submitting the shared form will${
    shareFormMeta.finalize_on_submit ? '' : ' not'
  } finalize this task. The shared form will close if you finalize the task normally.`;

  const handleShareFormFeature = (featureIndex, isFeatureSelected) => {
    let updatedSelectedFeatures = [...selectedFeatures];
    updatedSelectedFeatures[featureIndex].isSelected = !isFeatureSelected;
    const selectedFeatureId = updatedSelectedFeatures[featureIndex].id;
    updatedSelectedFeatures.forEach((e, ind, newArr) => {
      if (e.required_question_id === selectedFeatureId) {
        newArr[ind].isSelected = !isFeatureSelected;
      }
    });
    if (!isFeatureSelected) {
      const resourceId = selectedFeatures[featureIndex].resource_id;
      const resourceIndex = selectedFeatures.findIndex(e => e.resource_id === resourceId);
      updatedSelectedFeatures[resourceIndex].isSelected = true;
    }
    setSelectedFeatures(updatedSelectedFeatures);
  };
  const handleShareFormResource = (resourceId, isFeatureSelected) => {
    let updatedSelectedFeatures = [...selectedFeatures];
    selectedFeatures.forEach((e, index) => {
      if (e.resource_id === resourceId) {
        updatedSelectedFeatures[index].isSelected = !isFeatureSelected;
      }
    });
    setSelectedFeatures(updatedSelectedFeatures);
  };
  const handleShareFormValidate = () => {
    if (shareFormMeta.send_email && !shareFormMeta.email) {
      const emailError = 'Please provide an email to share form link to.';
      setShareFormEmailError(emailError);
      dispatch(setNotice({ type: 'warning', message: emailError }));
      return;
    }
    if (shareFormMeta.email) {
      const emailError = validateEmail(shareFormMeta.email);
      if (emailError) {
        setShareFormEmailError(emailError);
        dispatch(setNotice({ type: 'warning', message: emailError }));
        return;
      }
    }
    if (!shareFormMeta.finalize_on_submit || handleFormValidation(true)) {
      setIsSharingFormModalOpen(true);
    } else {
      // dispatch(setNotice({ type: 'warning', message: 'Before Sharing this form' }));
      setSubmitAttempted(true);
    }
  };
  const handleSubmitShareForm = () => {
    let features = selectedFeatures.reduce(
      (dict, { feature_type_id, isSelected, resource_id, resource_variable_name }) => {
        if (isSelected) {
          dict.push({ feature_type_id, resource_id, resource_variable_name });
        }
        return dict;
      },
      [],
    );
    dispatch(
      createOutsideForm({ features, ...shareFormMeta }, params.transactionId),
    ).then(e => setIsSharingForm(false));
    setIsSharingFormModalOpen(false);
  };
  const handleUpdateProjectName = value => {
    const trimmedLabel = (value + '').trim();
    if (trimmedLabel.length > 0 && trimmedLabel !== project.label.trim()) {
      dispatch(updateProject({ label: trimmedLabel }, params.projectId)).then(
        payload => {
          setProjectLabelInput(trimmedLabel);
        },
        error => {
          setProjectLabelInput(project.label);
        },
      );
    } else {
      setProjectLabelInput(project.label);
    }
  };

  useEffect(() => {
    if (shareFeatures.length !== selectedFeatures.length) {
      setSelectedFeatures(shareFeatures);
    }
  }, [setSelectedFeatures, shareFeatures, selectedFeatures.length]);
  useEffect(() => {
    let initValsLength = 0;
    const initValsArr = Object.values(initVals);
    let valuesLength = 0;
    const valuesArr = Object.values(values);
    for (let i = 0; i < valuesArr.length; i++) {
      for (let innerI = 0; innerI < valuesArr[i].length; innerI++) {
        if (!get(valuesArr, `[${i}][${innerI}].isInitial`, false)) {
          valuesLength++;
        }
      }
    }
    for (let i = 0; i < initValsArr.length; i++) {
      for (let innerI = 0; innerI < initValsArr[i].length; innerI++) {
        if (!get(initValsArr, `[${i}][${innerI}].isInitial`, false)) {
          initValsLength++;
        }
      }
    }
    if (initValsLength !== valuesLength) {
      resetForm({ values: initVals });
    }
  }, [resetForm, initVals, values]);

  return (
    <>
      {isSharingForm && (
        <div className="workbench-form workbench-form--share">
          <h3 className="workbench-form__heading">
            Share Form Information
            <small>
              <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
              Please answer the questions below and select whatever questions you want
              available in the shared form.
            </small>
          </h3>
          <InputStyledTextField
            label="Shared Form connected email"
            name="share-form-email"
            id="share-form-email"
            value={shareFormMeta.email}
            onChange={e => setShareFormMeta({ ...shareFormMeta, email: e.target.value })}
            error={shareFormEmailError}
            onBlur={e => {
              if (e.target.value || shareFormMeta.send_email) {
                setShareFormEmailError(validateEmail(e.target.value));
              } else {
                setShareFormEmailError('');
              }
            }}
          />
          <InputStyledTextField
            label="Shared Form Label"
            name="share-form-name"
            id="share-form-name"
            value={shareFormMeta.name}
            onChange={e => setShareFormMeta({ ...shareFormMeta, name: e.target.value })}
          />
          <InputTextarea
            label="Shared Form Description"
            name="share-form-description"
            id="share-form-description"
            value={shareFormMeta.description}
            onChange={e =>
              setShareFormMeta({ ...shareFormMeta, description: e.target.value })
            }
          />
          <InputCheckboxGroup
            id="share-form-toggle-submit"
            label="Do you want to finalize transaction when shared form is submitted?"
            name="share-form-toggle-submit"
            value={shareFormMeta.finalize_on_submit}
            type="radio"
            options={[
              { label: 'Finalize on submission', value: true },
              { label: 'Do not finalize on submission', value: false },
            ]}
            onChange={value =>
              setShareFormMeta({ ...shareFormMeta, finalize_on_submit: value })
            }
          />
          <InputCheckboxGroup
            id="share-form-toggle-email"
            label="Do you want to send an email with the shared form link?"
            name="share-form-toggle-email"
            value={shareFormMeta.send_email}
            type="radio"
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
            onChange={value => setShareFormMeta({ ...shareFormMeta, send_email: value })}
          />
        </div>
      )}
      <Form
        className={classnames('workbench-form', {
          'workbench-form--ios': isSafariMobile,
        })}
        id="workbench__form"
        autoComplete="nope"
      >
        {(isSubmitAttempted || submitCount > 0) &&
          (!isValid || formValidationErrors.length > 0) && (
            <div className="workbench-form__errors">
              {!isValid && <p>- Fill out all required fields before continuing.</p>}
              {formValidationErrors.length > 0 &&
                formValidationErrors.map((formError, i) => (
                  <p key={`form-error-${i}`}>{formError}</p>
                ))}
            </div>
          )}
        {isProjectNameInputShowing && (
          <div className="workbench-form__workflow-name">
            <InputStyledTextField
              label={projectNameQuestionLabel}
              sublabel={
                <>
                  {projectNameQuestionSublabel}
                  {projectLabelInput !== project.template.label && (
                    <Button
                      buttonType="link"
                      className="workbench-form__sublabel-link"
                      size="xs"
                      onClick={ev => handleUpdateProjectName(project.template.label)}
                    >
                      <FontAwesomeIcon icon={['fal', 'arrow-rotate-left']} />
                      Reset to Default Name
                    </Button>
                  )}
                </>
              }
              name="project-name"
              id="project-name"
              value={projectLabelInput}
              onChange={ev => setProjectLabelInput(ev.target.value)}
              onBlur={ev => handleUpdateProjectName(ev.target.value)}
            />
          </div>
        )}
        {questions.map((question, parentResourceIndex) => {
          const {
            custom_label,
            innerQuestions,
            isCompany,
            multi_entry,
            name,
            resourceArr = [],
            resource_type_id,
          } = question;

          let updatedLabel = custom_label + ' Name';
          let operatingStateInd = innerQuestions.findIndex(
            e => e.feature_type.name === 'operating_state',
          );
          const connectedResourceOptions = get(resources, `[${name}]`, []);
          let resourceOptions = get(resources, `[${resource_type_id}]`, []);
          if (name === 'Operating State') {
            resourceOptions = options;
          } else if (!isEmpty(connectedResourceOptions)) {
            let filteredResourceOptions = resourceOptions.reduce((dict, e) => {
              if (
                connectedResourceOptions.findIndex(
                  option => option.resourceId === e.resourceId,
                ) === -1
              ) {
                dict.push(e);
              }
              return dict;
            }, []);

            resourceOptions = [
              {
                label: name,
                options: connectedResourceOptions,
              },
              {
                label: resourceOptions[0].resourceType,
                options: filteredResourceOptions,
              },
            ];
          } else if (!isEmpty(resourceOptions[0])) {
            resourceOptions = [
              {
                label: resourceOptions[0].resourceType,
                options: resourceOptions,
              },
            ];
          }
          if (operatingStateInd !== -1) {
            updatedLabel = innerQuestions[operatingStateInd].question_text;
          }

          return (
            <FieldArray
              key={`multi-resource-${name}`}
              name={name}
              render={arrayHelpers => (
                <div className="workbench-form__group">
                  <h3 className="workbench-form__heading">
                    {upperFirst(custom_label)} Information
                    {!isCompany && resource_type_id !== 16 && !!multi_entry && (
                      <small>Enter the names of each {custom_label}:</small>
                    )}
                  </h3>
                  {resourceArr.length > 0 &&
                    resourceArr[0].resource_name &&
                    resourceArr.map((resource, index) => {
                      const shareFormResourceIndex = selectedFeatures.findIndex(
                        e =>
                          e.feature_type_id ===
                            resource.innerQuestions[0].feature_type_id &&
                          e.resource_id === resource.resource_id,
                      );
                      const isShareFormResourceSelected = get(
                        selectedFeatures,
                        `[${shareFormResourceIndex}].isSelected`,
                        false,
                      );
                      const entityType = get(
                        resource,
                        'innerQuestions[0].entityType',
                        '',
                      );
                      const isEntityFormed = get(
                        resource,
                        'innerQuestions[0].isEntityFormed',
                        false,
                      );
                      return (
                        <Fragment key={`resource-${resource.resource_name}`}>
                          {resource_type_id !== 6 && resource_type_id !== 25 && (
                            <>
                              {isSharingForm && (
                                <InputStyledCheckbox
                                  checked={isShareFormResourceSelected}
                                  parentStyle="workbench-form__share-form-feature"
                                  name={`share-form-feature-${shareFormResourceIndex}`}
                                  onClick={e =>
                                    handleShareFormResource(
                                      resource.resource_id,
                                      isShareFormResourceSelected,
                                    )
                                  }
                                  value={isShareFormResourceSelected}
                                />
                              )}
                              <Field
                                autoComplete="nope"
                                component={isCompany ? CompanyName : ResourceSelect}
                                isEntityFormed={isEntityFormed}
                                entityType={entityType}
                                handleDelete={handleDelete}
                                handleRemoveResource={() => {
                                  const resourceIds = resourceArr.reduce((dict, e) => {
                                    if (e.resource_id !== resource.resource_id) {
                                      dict.push(e.resource_id);
                                    }
                                    return dict;
                                  }, []);
                                  handleSetResourceIds(name, resourceIds);
                                }}
                                handleResourceName={name => {
                                  handleResourceName(
                                    {
                                      name,
                                    },
                                    resource.resource_id,
                                  );
                                }}
                                icon="user"
                                isDisabled={isSharingForm}
                                isDraftResource={resource.is_draft}
                                isCompany={isCompany}
                                isFixed={resource.innerQuestions[0].fixed}
                                questionHelp={
                                  resource.innerQuestions[0].question_help ||
                                  resource.innerQuestions[0].feature_type.question_help
                                }
                                sublabel={
                                  resource.innerQuestions[0].question_comment ||
                                  resource.innerQuestions[0].feature_type.question_comment
                                }
                                isWorkbench
                                label={`${updatedLabel}${
                                  !isCompany && !!multi_entry
                                    ? ' (#' + (index + 1) + ')'
                                    : ''
                                }`}
                                name={`${name}[${index}].resource`}
                                options={resourceOptions}
                                onResourceSelect={() => {}}
                                validate={val =>
                                  isCompany &&
                                  !resource.innerQuestions[0].fixed &&
                                  validateCompany(
                                    val,
                                    suffixOptions,
                                    entityType,
                                    isEntityFormed,
                                  )
                                }
                                params={params}
                                resource_id={resource.resource_id}
                                resourceTypeName={name}
                                resourceTypeCustomLabel={custom_label}
                                setLabel={() => {}}
                              />
                              {resource.is_draft && (
                                <ResourceDraft
                                  resource={resource}
                                  resourceOptions={resourceOptions}
                                  transactionId={params.transactionId}
                                />
                              )}
                            </>
                          )}
                          {resource.innerQuestions.map((innerQ, questionIndex) => {
                            const shareFormFeatureIndex = selectedFeatures.findIndex(
                              e =>
                                e.feature_type_id === innerQ.feature_type_id &&
                                e.resource_id === resource.resource_id,
                            );
                            const shareFormFeature =
                              selectedFeatures[shareFormFeatureIndex] || {};
                            if (
                              innerQ.isHidden ||
                              innerQ.feature_type.name === 'operating_state' ||
                              innerQ.feature_type.name === 'name'
                            ) {
                              return null;
                            }
                            return (
                              <Fragment key={`inner-questions-${index}-${innerQ.id}`}>
                                {isSharingForm && (
                                  <InputStyledCheckbox
                                    checked={shareFormFeature.isSelected}
                                    isDisabled={shareFormFeature.isDisabled}
                                    parentStyle={classnames(
                                      'workbench-form__share-form-feature',
                                      {
                                        'workbench-form__share-form-feature--no-padding':
                                          [
                                            'address_universal',
                                            'address',
                                            'boolean',
                                            'checkbox_group',
                                            'comment',
                                            'custom_document',
                                            'document',
                                            'radio',
                                            'resource_list',
                                            'share-links',
                                            'template_document',
                                            'text',
                                          ].includes(innerQ.feature_type.feature_type),
                                      },
                                    )}
                                    name={`share-form-feature-${shareFormFeatureIndex}`}
                                    onClick={e =>
                                      handleShareFormFeature(
                                        shareFormFeatureIndex,
                                        shareFormFeature.isSelected,
                                      )
                                    }
                                    tooltip={
                                      innerQ.required_question_id &&
                                      'selection determined by prerequisite parent question.'
                                    }
                                    value={shareFormFeature.isSelected}
                                  />
                                )}
                                <Question
                                  handleFormBlur={e => {
                                    if (
                                      isCompany &&
                                      innerQ.feature_type.name === 'name'
                                    ) {
                                      return handleResourceName(
                                        {
                                          name: e.target.value,
                                        },
                                        resource.resource_id,
                                      );
                                    } else if (e.target.value !== innerQ.value) {
                                      return handleFormBlur(
                                        e.target.value,
                                        innerQ.feature_type.id,
                                        innerQ.feature_id,
                                        resource.resource_id,
                                        resource_type_id,
                                      );
                                    }
                                  }}
                                  featureProps={innerQ}
                                  isSharingForm={isSharingForm}
                                  params={params}
                                  resourceTypeName={name}
                                  resourceIndex={index}
                                  resource_id={resource.resource_id}
                                  resource_name={resource.resource_name}
                                />
                              </Fragment>
                            );
                          })}
                          {!isCompany && !!multi_entry && !!name && (
                            <div className="workbench-form__actions">
                              <Button
                                buttonType="link"
                                isWarning
                                size="sm"
                                onClick={() => {
                                  const resourceIds = resourceArr.reduce((dict, e) => {
                                    if (e.resource_id !== resource.resource_id) {
                                      dict.push(e.resource_id);
                                    }
                                    return dict;
                                  }, []);
                                  const resourceName = resource.resource_name;
                                  handleSetResourceIds(name, resourceIds).then(e => {
                                    setNotice(`${resourceName} removed.`);
                                  });
                                  // arrayHelpers.remove(index);
                                }}
                              >
                                <FontAwesomeIcon icon={['fal', 'trash-alt']} />
                                Remove {resource.resource_name}
                              </Button>
                            </div>
                          )}
                          {!isCompany && !!multi_entry && !!name && (
                            <div className="workbench-form__actions">
                              {isArray(values[name]) &&
                                index === values[name].length - 1 &&
                                get(values, `${name}[${index}].resource`, false) && (
                                  <Button
                                    buttonType="link"
                                    size="sm"
                                    onClick={() => {
                                      const updatedValue = innerQuestions.reduce(
                                        (dict, e) => {
                                          dict = {
                                            ...dict,
                                            [e.feature_type.question_label]: '',
                                          };
                                          return {
                                            ...dict,
                                            isInitial: true,
                                          };
                                        },
                                        {},
                                      );
                                      arrayHelpers.push(updatedValue);
                                    }}
                                    isDisabled={isFetching}
                                  >
                                    <FontAwesomeIcon icon={['fal', 'plus']} /> Add Another{' '}
                                    {custom_label}
                                  </Button>
                                )}
                            </div>
                          )}
                        </Fragment>
                      );
                    })}
                  {!!name &&
                    isArray(values[name]) &&
                    (resourceArr.length !== values[name].length || // Add Individual
                      resourceArr.length === 0) && ( // No resources yet
                      <>
                        <Field
                          autoComplete="nope"
                          component={ResourceSelect}
                          handleDelete={handleDelete}
                          handleResourceName={resourceName => {
                            let resourceIds = resourceArr.reduce((dict, e) => {
                              dict.push(e.resource_id);
                              return dict;
                            }, []);
                            if (resourceName && resourceName.resourceId) {
                              resourceIds.push(resourceName.resourceId);
                              return handleSetResourceIds(name, resourceIds);
                            } else if (resourceName) {
                              handleResourceName({
                                resource_type_id,
                                name: resourceName.label,
                              }).then(resource => {
                                resourceIds.push(resource.id);
                                handleSetResourceIds(name, resourceIds);
                                setSubmitAttempted(false);
                                resetForm({ values: initVals });
                              });
                            }
                          }}
                          icon="user"
                          isCreatable
                          isFetching={isFetching}
                          isNewResource
                          placeholder="Type to search or create new..."
                          questionHelp={
                            innerQuestions[0].question_help ||
                            innerQuestions[0].feature_type.question_help
                          }
                          sublabel={
                            innerQuestions[0].question_comment ||
                            innerQuestions[0].feature_type.question_comment
                          }
                          label={updatedLabel}
                          name={`${name}[${values[name].length - 1}].resource`}
                          options={resourceOptions}
                          params={params}
                          resourceTypeName={name}
                          resourceTypeCustomLabel={custom_label}
                          setLabel={() => {}}
                        />
                        <div className="workbench-form__actions">
                          {resourceArr.length !== 0 && values[name].length !== 1 && (
                            <Button
                              buttonType="link"
                              isWarning
                              onClick={() => arrayHelpers.pop()}
                              size="sm"
                            >
                              <FontAwesomeIcon icon={['fal', 'trash-alt']} />
                              Remove {name}
                            </Button>
                          )}
                          {!isCompany && !!multi_entry && !!name && (
                            <Button
                              buttonType="link"
                              size="sm"
                              onClick={() => {}}
                              isDisabled
                            >
                              <FontAwesomeIcon icon={['fal', 'plus']} /> Add Another{' '}
                              {custom_label}
                            </Button>
                          )}
                        </div>
                      </>
                    )}
                  {parentResourceIndex + 1 < questions.length && (
                    <div className="workbench-form__divider" />
                  )}
                </div>
              )}
            />
          );
        })}
        {customDocuments.length > 0 && (
          <div className="workbench-form__group">
            <h3 className="workbench-form__heading">Custom Documents Information</h3>
            <FieldArray
              name="custom_documents"
              render={arrayHelpers =>
                customDocuments.map((customDoc, customDocIndex) => {
                  return (
                    <Field
                      key={`custom-documents-${customDoc.name}`}
                      autoComplete="nope"
                      customDoc={customDoc}
                      component={FeatureTypes['custom_document'].Formik}
                      handleFormValidation={handleFormValidation}
                      isSubmitAttempted={isSubmitAttempted}
                      params={params}
                      name={`custom_documents[${customDocIndex}]`}
                      label={customDoc.question_text}
                      customDocIndex={customDocIndex}
                      sublabel={customDoc.question_comment}
                    />
                  );
                })
              }
            />
          </div>
        )}
        {workflowCounselQuestions.length > 0 && isInitQuestionnaire && (
          <div className="workbench-form__group">
            <h3 className="workbench-form__heading">Workflow Counsel Information</h3>
            <FieldArray
              name="counsel_options"
              render={arrayHelpers =>
                workflowCounselQuestions.map((counselQuestion, counselQuestionIndex) => {
                  return (
                    <Field
                      key={`counsel-options-${counselQuestion.id}`}
                      autoComplete="nope"
                      component={Select}
                      feature_type="options"
                      handleFormBlur={e => {
                        handleCounselBlur(counselQuestion.formikValue, e.target.value);
                      }}
                      isCounselOptions
                      isRequired={!!counselQuestion.required}
                      name={`counsel_options[${counselQuestionIndex}]`}
                      options={counselQuestion.options}
                      label={counselQuestion.label}
                      validate={!!counselQuestion.required && validateSelect}
                    />
                  );
                })
              }
            />
          </div>
        )}
        {(isSubmitAttempted || submitCount > 0) &&
          (!isValid || formValidationErrors.length > 0) && (
            <div className="workbench-form__errors">
              {!isValid && <p>- Fill out all required fields before continuing.</p>}
              {formValidationErrors.length > 0 &&
                formValidationErrors.map((formError, i) => (
                  <p key={`form-error-${i}`}>{formError}</p>
                ))}
            </div>
          )}
        <div
          className={classnames('workbench-form__bottom', {
            'workbench-form__bottom--modal': isModal,
          })}
        >
          {!!isReopeningTimeout && (
            <h4 className="workbenchTaskOverview__description">
              <FontAwesomeIcon className="error" icon="exclamation-circle" />
              Since you just reopened this task, please wait a few more seconds before
              submitting this task to allow everything to update correctly on our end.
            </h4>
          )}
          <WorkbenchActions
            params={params}
            isAssessment={isAssessment}
            isFetching={isFetching || isSubmitting}
            isDisabled={isSubmitting || isReopeningTimeout}
            isForm
            isModal={isModal}
            isSharingForm={isSharingForm}
            onSecondaryClick={() => setIsSharingForm(false)}
            onPrimaryClick={handleShareFormValidate}
            projectTemplateId={project.template.id}
            isInitQuestionnaire={isInitQuestionnaire}
          />
        </div>
      </Form>
      <AlertModal
        isExclamationShowing
        header="Are you sure you want to create this shared form?"
        body={shareFormFinalizeBody}
        isOpen={isSharingFormModalOpen}
        onClose={() => setIsSharingFormModalOpen(false)}
        onPrimaryClick={handleSubmitShareForm}
        primaryActionText="Create Shared Form"
      />
    </>
  );
};

const Question = ({
  featureProps,
  handleFormBlur,
  isSharingForm,
  params,
  resourceTypeName,
  resourceIndex,
  resource_id,
  resource_name,
  isDisabled,
}) => {
  const {
    feature_type: {
      calendly_options,
      feature_type,
      filename,
      is_creatable,
      json: { markdown = '' } = {},
      multi_entry_feature,
      options,
      path: qpath,
      question_comment: qc,
      question_help: qh,
    } = {},
    feature_type_id,
    fixed,
    is_label,
    is_required,
    json: { markdown: flattenedMarkdown = '' } = {},
    path,
    question_comment,
    question_help,
    question_label,
    question_placeholder,
    question_text,
    resourceListData = {},
    tags,
    value: savedValue,
  } = featureProps;
  const updatedName = `${resourceTypeName}[${resourceIndex}].${question_label}`;
  let updatedOptions = calendly_options || options;
  if (feature_type === 'detailed_options') {
    updatedOptions = JSON.parse(options);
  }
  if (feature_type === 'related_resource') {
    updatedOptions = reduce(
      resourceListData,
      (dict, e) => {
        const individual = get(e, '[0]', {});
        dict.push({
          label: individual.resource,
          value: individual.resource_id,
          resource_type: individual.resource_type,
          finalized: individual.finalized,
        });
        return dict;
      },
      [],
    );
  }

  return (
    <Field
      autoComplete="nope"
      component={FeatureTypes[feature_type].Formik}
      featureProps={featureProps}
      feature_type={feature_type}
      feature_type_id={feature_type_id}
      filename={filename}
      handleFormBlur={handleFormBlur}
      isCreatable={!!is_creatable}
      isDetailedOptions={feature_type === 'detailed_options'}
      isDisabled={isDisabled || isSharingForm}
      isFixed={!!fixed || isDisabled}
      isLabel={!!is_label}
      isRequired={!!is_required}
      label={question_text}
      markdown={markdown || flattenedMarkdown}
      multiEntry={multi_entry_feature}
      name={updatedName}
      options={updatedOptions}
      params={params}
      path={path || qpath}
      placeholder={question_placeholder}
      questionHelp={question_help || qh}
      resource_id={resource_id}
      resource_name={resource_name}
      resetValue={FeatureTypes[feature_type].value}
      resourceTypeName={resourceTypeName}
      setLabel={() => {}}
      sublabel={question_comment || qc}
      tags={tags}
      taskId={params.transactionId}
      validate={!!is_required && FeatureTypes[feature_type].validate}
      savedValue={savedValue}
    />
  );
};

const ResourceDraft = ({ resource, resourceOptions, transactionId }) => {
  const { resource_name, resource_id, resource_type } = resource;
  const dispatch = useDispatch();

  resourceOptions = resourceOptions.map(e => ({
    ...e,
    options: e.options.filter(e => !e.is_draft),
  }));

  const [isConnectingDraft, setIsConnectingDraft] = useState(false);
  const handleMergeResources = baseResource => {
    const message = `Are you sure you want to connect ${resource_name} with ${baseResource.label}? Any overlapping inputs of ${baseResource.label} will be overwritten.`;
    if (window.confirm(message)) {
      dispatch(mergeResources(baseResource.resourceId, resource_id, transactionId));
    } else {
      setIsConnectingDraft(false);
    }
  };
  return (
    <>
      <InputStyledCheckbox
        name={`is-connecting-draft-${resource_name}`}
        label={`Do you want to connect ${resource_name} to an existing ${resource_type}?`}
        checked={isConnectingDraft}
        value={isConnectingDraft}
        onClick={() => setIsConnectingDraft(!isConnectingDraft)}
      />
      {isConnectingDraft && (
        <InputSelect
          options={resourceOptions}
          onChange={handleMergeResources}
          name={`select-connected-resource`}
          label={`Select the ${resource_type} you want to connect ${resource_name} to:`}
        />
      )}
    </>
  );
};

WorkbenchForm.propTypes = {
  isFetching: PropTypes.bool,
  formTitle: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  values: PropTypes.object,
};

WorkbenchForm.defaultProps = {
  status: {},
};

export default WorkbenchForm;
