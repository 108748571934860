import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik } from 'formik';

import { Button, MyModal } from '../../components/common';
import { Select, TextField } from '../../components/formik';
import { createNewCompany } from '../../redux/modules/Company/operations';
import { isCompanyFetching, MyCompanies } from '../../redux/modules/Company/selectors';
import { validateSelect, validateString } from '../../utils/FeatureTypes';
import MyTypes from '../../utils/MyTypes';

import './NewCompany.scss';
import { BrandMeta } from '../../redux/modules/UI/selectors';

const customStyles = {
  overlay: {
    zIndex: 9,
    backgroundColor: 'rgba(0,0,0,.66)',
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    overflowY: 'auto',
    paddingTop: 20,
    WebkitOverflowScrolling: 'touch',
  },
  content: {
    position: 'static',
    maxWidth: 450,
    height: 400,
    margin: 'auto',
    padding: 0,
    border: 'none',
    boxShadow: '3px 3px 3px 3px #33373859',
    display: 'flex',
    flexDirection: 'column',
  },
};

export const businessTypeOptions = [
  { label: 'For-profit Company', value: 'For-profit Company' },
  { label: 'Investment Vehicle', value: 'Investment Vehicle' },
  { label: 'Legal/Accounting Provider', value: 'Legal/Accounting Provider' },
  { label: 'Other', value: 'Other' },
];

const NewCompany = ({
  history: { action, goBack, replace },
  location: { hash, pathname, search, state },
}) => {
  const { background } = state || {};
  const brandMeta = useSelector(BrandMeta);
  const { partner_slug, account_business_type, show_business_types } =
    brandMeta.assets || {};
  const isWhiteLabel = window.location.href.includes('paperos');
  const dispatch = useDispatch();
  const isFetching = useSelector(isCompanyFetching);
  const companies = useSelector(MyCompanies);
  const [isSubmitted, setSubmitted] = useState(false);

  return (
    <MyModal
      isOpen={pathname.includes('/new-company')}
      onRequestClose={e => {
        e.stopPropagation();
        if (action !== 'POP') {
          goBack();
        } else if (background) {
          replace({ ...background, ...state });
        } else {
          replace('/c/0/company-select');
        }
      }}
      style={customStyles}
    >
      <div className="new-company">
        <h2>
          <FontAwesomeIcon icon="exclamation-circle" />
          Add Workspace to your User Account
        </h2>
        <Formik
          initialValues={{
            company_name: '',
            business_type:
              (account_business_type && {
                label: account_business_type,
                value: account_business_type,
              }) ||
              '',
          }}
          onSubmit={({ company_name, business_type }, actions) => {
            setSubmitted(true);
            const body = {
              company_name,
              features: [
                { feature_type_id: 881, value: business_type?.value || '' },
                { feature_type_id: 1583, value: partner_slug || '' },
              ],
            };
            dispatch(
              createNewCompany(body, { search, hash }, companies.length === 0),
            ).then(
              e => {},
              error => setSubmitted(false),
            );
          }}
        >
          {formikProps => (
            <Form>
              <Field
                component={TextField}
                label="Company Name:"
                name="company_name"
                validate={validateString}
                sublabel="Add a Workspace for any of your new and/or existing organizations, companies and entities."
              />
              {(!isWhiteLabel || !!show_business_types) && (
                <Field
                  component={Select}
                  isValidatingOnBlur={false}
                  label="What is the purpose of this business entity?"
                  name="business_type"
                  options={businessTypeOptions}
                  validate={validateSelect}
                />
              )}
              <div className="new-company__action">
                <Button
                  type="submit"
                  isDisabled={isFetching || isSubmitted}
                  isFetching={isFetching || isSubmitted}
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {/* </div> */}
    </MyModal>
  );
};

NewCompany.propTypes = {
  fetchError: PropTypes.string,
  isFetching: PropTypes.bool,
  initData: MyTypes.initData,
  myModule: PropTypes.object,
};

export default NewCompany;
