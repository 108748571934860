import React, { Fragment, useCallback, useState } from 'react';
// import React, { Fragment, useCallback, useEffect, useState } from 'react';
// import enLocaleData from 'react-intl/locale-data/en';

// Not needed unless working with non "en" locales
// import { addLocaleData } from 'react-intl';
// import enLocaleData from 'react-intl/locale-data/en';

import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContentPicker from 'box-ui-elements/es/elements/content-picker';
import ContentPreview from 'box-ui-elements/es/elements/content-preview';
import messages from 'box-ui-elements/i18n/en-US';
import classnames from 'classnames';
import { Field } from 'formik';
import { startCase } from 'lodash-es';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';

import savviLogo from '../../../assets/images/savvi_logo.svg';
import { BoxToken } from '../../../redux/modules/Cabinet/selectors';
import {
  createCustomDocument,
  updateCustomDocument,
} from '../../../redux/modules/Transaction/operations';
import {
  MyTemplatesWithHooks,
  isFetchingWithHooks,
} from '../../../redux/modules/Transaction/selectors';
import { setNotice } from '../../../redux/modules/UI/actions';
import FeatureTypes from '../../../utils/FeatureTypes';
import { InputHelp, InputStyledTextField } from '../../inputs';
import { Select, TextField } from '..';

import './FormCustomDoc.scss';
import { Button } from '../../common';

const allowedExts = ['pdf', 'doc', 'docx', 'txt', 'rtf', 'odt', 'wpd'];

const FormCustomDoc = ({
  customDoc,
  customDocIndex,
  field: { name, value },
  form: { errors, setFieldValue, status, touched, values },
  questionHelp,
  handleFormValidation,
  isSubmitAttempted,
  params,
  label,
  sublabel,
}) => {
  const dispatch = useDispatch();

  const { account_task_id, custom_document, task_template_id } = customDoc;

  const boxToken = useSelector(BoxToken);
  const myTemplates = useSelector(MyTemplatesWithHooks);
  const isFetching = useSelector(isFetchingWithHooks);

  const [isPreviewShowing, setPreviewShowing] = useState(false);
  const [selectedDocId, setSelectedDocId] = useState('');

  const [isCustomDocShowing, setCustomDocShowing] = useState(!isEmpty(custom_document));
  const [selectedFolderId, setSelectedFolderId] = useState('');

  const [isEditingDocName, setEditingDocName] = useState(false);
  const [customDocName, setCustomDocName] = useState(
    (!isEmpty(custom_document) && custom_document.filename) || '',
  );
  const [updatedDocName, setUpdatedDocName] = useState('');

  const pickerRef = useCallback(node => {
    if (node !== null) {
      node.scrollIntoView();
    }
  }, []);

  // useEffect(() => {
  //   addLocaleData(enLocaleData);
  // }, [dispatch]);

  const apiError = get(status, name, false);
  const isTouched = get(touched, name, false);
  const error = apiError || (isTouched && get(errors, name, null));

  return (
    <div
      className={classnames('workbench-form__group', {
        'workbench-form__group--has-error': !!error,
      })}
    >
      {!isCustomDocShowing && (
        <>
          <label className="formCustomDoc__header">
            {label}
            {questionHelp && <InputHelp text={questionHelp} />}
          </label>
          {!selectedFolderId && (
            <Field
              component={Select}
              label="Select which templates you would like to choose from:"
              sublabel={sublabel}
              name={`${name}.folderSelect`}
              options={myTemplates.map(e => ({ label: e.folder_name, value: e.id }))}
              handleFormBlur={e => {
                setSelectedFolderId(e.target.value);
              }}
              isRequired
            />
          )}
          {boxToken && selectedFolderId && !isPreviewShowing && (
            <>
              <div ref={pickerRef} className="formCustomDoc__picker">
                <ContentPicker
                  autoFocus
                  token={boxToken}
                  extensions={allowedExts}
                  logoUrl={savviLogo}
                  rootFolderId={selectedFolderId}
                  contentUploaderProps={{
                    fileLimit: 1,
                    onBeforeUpload: e => {
                      document.querySelector('.bcu .bcu-footer').scrollIntoView();
                      if (!allowedExts.includes(e[0].name.split('.').reverse()[0])) {
                        dispatch(
                          setNotice({
                            type: 'error',
                            message: `Unable to upload file type. Please choose one of the following types: ${allowedExts.join(
                              ', ',
                            )}`,
                          }),
                        );
                        setTimeout(() =>
                          document.querySelector('.bcu .bcu-item-action button').click(),
                        );
                      }
                    },
                    onUpload: e => {
                      setSelectedDocId(e.id);
                      setPreviewShowing(true);
                    },
                  }}
                  maxSelectable={1}
                  onChoose={e => {
                    setSelectedDocId(e[0].id);
                    setPreviewShowing(true);
                  }}
                  // language="en-US"
                  messages={messages}
                />
              </div>
              <p className="formCustomDoc__text">
                <FontAwesomeIcon
                  className="formCustomDoc__highlight"
                  icon="exclamation-circle"
                />{' '}
                Custom Documents only work with certain extensions(
                {allowedExts.join(', ')})
              </p>
              <Button
                buttonType="secondary"
                className="formCustomDoc__action"
                isDisabled={isFetching}
                size="sm"
                onClick={() => setSelectedFolderId('')}
              >
                Select Different Folder
              </Button>
            </>
          )}
          {isPreviewShowing && (
            <>
              <div className="formCustomDoc__preview">
                <ContentPreview
                  fileId={selectedDocId}
                  token={boxToken}
                  hasHeader={true}
                  // language="en-US"
                  messages={messages}
                  onClose={e => {
                    setPreviewShowing(false);
                    setSelectedDocId('');
                  }}
                />
              </div>
              <p className="formCustomDoc__text">
                <FontAwesomeIcon
                  className="formCustomDoc__highlight"
                  icon="exclamation-circle"
                />{' '}
                If you want to use a customized version of this document, go ahead and
                download, edit, & then go back to your template folder to upload your
                edited document.
              </p>
              <div className="formCustomDoc__btns">
                <Button
                  buttonType="secondary"
                  isDisabled={isFetching}
                  size="sm"
                  onClick={() => {
                    setPreviewShowing(false);
                    setSelectedDocId('');
                  }}
                >
                  Choose Different Document
                </Button>
                <Button
                  isDisabled={isFetching}
                  isFetching={isFetching}
                  size="sm"
                  onClick={() => {
                    dispatch(
                      createCustomDocument(
                        {
                          file_id: selectedDocId,
                          account_task_id,
                          task_template_id,
                        },
                        params.transactionId,
                      ),
                    ).then(payload => {
                      setCustomDocShowing(true);
                      setSelectedDocId('');
                      setPreviewShowing(false);
                      setCustomDocName(
                        payload.custom_documents[0].custom_document.filename,
                      );
                      if (isSubmitAttempted) {
                        handleFormValidation();
                      }
                    });
                  }}
                >
                  Select Document
                </Button>
              </div>
            </>
          )}
        </>
      )}
      {isCustomDocShowing && (
        <>
          <div className="formCustomDoc__headerWrapper">
            <h4 className="formCustomDoc__header">
              <strong>Document Name: </strong>
              {!isEditingDocName && (
                <>
                  {customDocName}
                  {custom_document.tokens.length > 0 && (
                    <Button
                      buttonType="icon"
                      onClick={() => {
                        setUpdatedDocName(customDocName);
                        setEditingDocName(true);
                      }}
                      icon={['fal', 'edit']}
                    />
                  )}
                </>
              )}
              {isEditingDocName && (
                <InputStyledTextField
                  autoComplete="nope"
                  className="formCustomDoc__headerInput"
                  isFetching={isFetching}
                  value={updatedDocName}
                  onChange={e => setUpdatedDocName(e.target.value)}
                  onBlur={e => {
                    const {
                      target: { value },
                    } = e;
                    if (value !== custom_document.filename && value !== '') {
                      dispatch(
                        updateCustomDocument(
                          {
                            ...custom_document,
                            filename: value,
                          },
                          customDocIndex,
                          params.transactionId,
                        ),
                      ).then(payload => {
                        setCustomDocName(updatedDocName);
                        setEditingDocName(false);
                      });
                    }
                  }}
                />
              )}
            </h4>
          </div>
          {custom_document.tokens.length === 0 && (
            <>
              <h4 className="formCustomDoc__subheader">Document Tokens:</h4>
              <p className="formCustomDoc__text">
                <FontAwesomeIcon
                  className="formCustomDoc__warning"
                  icon="exclamation-circle"
                />
                We could not find any tokens for us to use in your Custom Document. Please
                View{' '}
                <a
                  href="https://learn.savvi.legal/custom-document-format"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Custom Document Guidelines
                </a>{' '}
                on how to set up a Custom Document.
              </p>
            </>
          )}
          {custom_document.tokens.length > 0 && (
            <>
              <h4 className="formCustomDoc__subheader">
                Document Tokens:
                {isEmpty(custom_document.notes) && (
                  <span className="formCustomDoc__highlight">*</span>
                )}
              </h4>
              {custom_document.tokens.map((token, index) => {
                const noteIndex = get(custom_document, 'notes', []).findIndex(
                  note => note.token === token,
                );
                let note = {};
                if (noteIndex !== -1) {
                  note = custom_document.notes[noteIndex];
                }
                return (
                  <Fragment key={`custom-doc-token-${token}`}>
                    <p className="formCustomDoc__text">
                      <strong>Token #{index + 1}: </strong>
                      {token}
                      {noteIndex !== -1 && <span className={note.level}>*</span>}
                    </p>
                    {!isEmpty(note) && (
                      <p className="formCustomDoc__text">
                        <small className={note.level}>
                          *{note.level}- {note.message}
                        </small>
                      </p>
                    )}
                  </Fragment>
                );
              })}
              {isEmpty(custom_document.notes) && (
                <p className="formCustomDoc__text">
                  <small>
                    <span className="formCustomDoc__highlight">*</span> If these look
                    incorrect, please view our{' '}
                    <a
                      href="https://learn.savvi.legal/custom-document-format"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Custom Document Guidelines
                    </a>{' '}
                    about correctly setting up tokens.
                  </small>
                </p>
              )}
            </>
          )}
          {get(custom_document, 'notes', []).findIndex(e => !e.token) !== -1 && (
            <>
              <h4 className="formCustomDoc__subheader">Other Notes:</h4>
              {custom_document.notes.map(
                (e, ind) =>
                  !e.token && (
                    <p className="formCustomDoc__text" key={`other-notes-${ind}`}>
                      <span className={e.level}>- {e.message}</span>
                    </p>
                  ),
              )}
            </>
          )}
          {!custom_document.valid && custom_document.tokens.length > 0 && (
            <p className="formCustomDoc__text">
              <FontAwesomeIcon
                className="formCustomDoc__error"
                icon="exclamation-circle"
              />
              This Document is not valid. Please View{' '}
              <a
                href="https://learn.savvi.legal/custom-document-format"
                target="_blank"
                rel="noopener noreferrer"
              >
                Custom Document Guidelines
              </a>{' '}
              on how to set up a Custom Document.
            </p>
          )}
          {!!custom_document.valid && Object.keys(custom_document.fields).length > 0 && (
            <>
              <h4 className="formCustomDoc__subheader">
                <FontAwesomeIcon
                  className="formCustomDoc__highlight"
                  icon="exclamation-circle"
                />
                Need input for {Object.keys(custom_document.fields).length} Field(s)
              </h4>
              {Object.entries(custom_document.fields).map(
                ([fieldKey, fieldVal], index) => {
                  return (
                    <Field
                      key={`field-${fieldKey}-${index}`}
                      component={TextField}
                      handleFormBlur={e => {
                        let updatedFields = {
                          ...custom_document.fields,
                          [fieldKey]: { role: fieldVal.role, value: e.target.value },
                        };

                        dispatch(
                          updateCustomDocument(
                            {
                              ...custom_document,
                              fields: updatedFields,
                            },
                            customDocIndex,
                            params.transactionId,
                          ),
                        ).then(e => {
                          if (isSubmitAttempted) {
                            handleFormValidation();
                          }
                        });
                      }}
                      label={startCase(fieldKey)}
                      name={`${name}.fields.${fieldKey}`}
                      params={params}
                      isRequired
                      validate={get(
                        FeatureTypes,
                        `${fieldKey}.validate`,
                        FeatureTypes.name.validate,
                      )}
                    />
                  );
                },
              )}
            </>
          )}
          {!!custom_document.valid && custom_document.recipients.length > 0 && (
            <>
              <h4 className="formCustomDoc__subheader">
                <FontAwesomeIcon
                  className="formCustomDoc__highlight"
                  icon="exclamation-circle"
                />
                Need info about {custom_document.recipients.length} Recipient(s)
              </h4>
              {custom_document.recipients.map((recipient, index) =>
                Object.entries(recipient).map(([recipientKey, recipientVal], ind) => {
                  if (recipientKey === 'role') {
                    return (
                      <Fragment key={`recipient-${recipientKey}-${index}-${ind}`}>
                        <p className="formCustomDoc__text">
                          <strong>Recipient #{index + 1}:</strong> {recipientVal}
                        </p>
                      </Fragment>
                    );
                  }
                  return (
                    <Field
                      key={`recipient-${recipientKey}-${ind}`}
                      component={TextField}
                      handleFormBlur={e => {
                        let updatedRecipients = custom_document.recipients
                          .slice()
                          .map((individual, recipientIndex) => {
                            let updatedIndividual = individual;
                            if (recipientIndex === index) {
                              updatedIndividual[recipientKey] = e.target.value;
                            }
                            return updatedIndividual;
                          });

                        dispatch(
                          updateCustomDocument(
                            {
                              ...custom_document,
                              recipients: [...updatedRecipients],
                            },
                            customDocIndex,
                            params.transactionId,
                          ),
                        ).then(e => {
                          if (isSubmitAttempted) {
                            handleFormValidation();
                          }
                        });
                      }}
                      label={startCase(recipientKey)}
                      name={`${name}.recipients[${index}].${recipientKey}`}
                      params={params}
                      isRequired
                      validate={FeatureTypes[recipientKey].validate}
                    />
                  );
                }),
              )}
            </>
          )}
          <Button
            buttonType="secondary"
            isDisabled={isFetching}
            size="sm"
            onClick={() => setCustomDocShowing(false)}
          >
            Select Different Document
          </Button>
        </>
      )}
      {/* <InputSublabel text={sublabel} /> */}
      {/* <InputFeedback error={error} /> */}
    </div>
  );
};

export default withRouter(FormCustomDoc);
